import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/icon.png';
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../actions/auth/auth';

export default function ForgetPage() {
    const { t, i18n } = useTranslation();
    const { email, setEmail } = useState("");

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();
        console.log(email);
        dispatch(reset(email))
            .then((res) => {
                console.log(res);
                // email.value = ''
                // window.location.reload;
            }).catch(() => {

            })
    }

    return (
        <div className="flex items-center justify-center h-screen bg-white dark:bg-dark-main">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-second shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex items-center justify-center mb-3">
                        <img src={Logo} alt="" className="w-20 sm:w-10 lg:w-20 h-auto" />
                    </div>
                    {message && (
                        <div className='form-group'>
                            <div className='alert alert-danger' role='alert'>
                                {message}
                            </div>
                        </div>
                    )}
                    <p className="text-center text-gray-500 text-xm mb-4 mt-4">
                        You forgot your password? Here you can easily retrieve a new password.
                    </p>
                    <div className="mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="email-input"
                            type="email"
                            placeholder='Email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>

                    <button
                        className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        id='button-input'
                    >
                        Reset
                    </button>
                    <div className="text-center mt-2">
                        <Link to="/auth/login" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Already have Account?, Login
                        </Link>
                    </div>
                    <div className="text-center mt-2">
                        <Link to="/auth/register" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Dont have Account?, Register
                        </Link>
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy; trafalines.com Community {(new Date().getFullYear())}
                </p>
            </div>
        </div>
    );

}
