import { faBookmark, faComment, faEllipsisH, faHeart, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';

const PostCardDownload = ({ post }) => {
    const images = post.posts_images;
    const now = moment().startOf('day');
    const createdAt = moment(post.created_at).startOf('day');

    const isOlderThanAYear = now.diff(createdAt, 'years') >= 1;

    const formattedDate = isOlderThanAYear ? createdAt.format('D MMMM YYYY') : createdAt.fromNow();
    return (
        <div className="bg-white dark:bg-dark-second dark:text-dark-txt mt-4 rounded-none">
            <div className="flex items-center justify-between px-4 py-2">
                <div className="flex space-x-2 items-center">
                    <div className="relative">
                        <img src={post.users.profile_img_small} alt="Profile" className="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-500" />
                        {post.users.is_online && (
                            <span className="bg-green-500 w-3 h-3 rounded-full absolute left-[-0.5px] top-[-0.5px] border-white border-2"></span>
                        )}
                    </div>
                    <div className='flex-wrap space-y-0 justify-center items-start mt-[-10px]'>
                        <div className="font-normal text-xs text-black dark:text-white">
                            {post.users.username}
                        </div>
                        <span className="text-[10px] text-gray-500">
                            {formattedDate}
                        </span>
                    </div>
                </div>

                <div className="w-8 h-8 grid place-items-center text-xl text-gray-500 hover:bg-gray-200 dark:text-dark-txt dark:hover:bg-dark-third rounded-full">
                    <FontAwesomeIcon icon={faEllipsisH} className="text-gray-400 text-xl" />
                </div>


            </div>
            <div className="py-2">
                <img
                    className="w-full max-h-[350px] object-cover bg-gray-300 dark:bg-gray-500"
                    src={images[0].files_large_thumbnail}
                    alt=""
                    onError={(e) => {
                        e.currentTarget.src = '/path/to/fallback-image.jpg';
                    }}
                />
            </div>
            <div className="px-4 py-1">
                <div className="flex items-center justify-between px-3">
                    <div className="text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                        <FontAwesomeIcon icon={faHeart} className={`${post.is_liked ? 'text-red-500' : 'text-dark-700 dark:text-gray-400'} text-xl`} />
                    </div>
                    <div className="text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                        <FontAwesomeIcon icon={faComment} className="text-dark-700 dark:text-gray-400 text-xl" />
                    </div>
                    <div className="text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                        <FontAwesomeIcon icon={faShare} className="text-dark-700 dark:text-gray-400 text-xl" />
                    </div>
                    <div className="text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                        <FontAwesomeIcon icon={faBookmark} className={`${post.is_saved ? 'text-green-500' : 'text-dark-700 dark:text-gray-400'} text-xl`} />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PostCardDownload;
