import { combineReducers } from "redux";
// import profile from "./users/account/ProfileReducers";
// import birthday from "./users/birthday/BirthdayReducers";
// import messenger from "./users/messenger/MessengerReducers";
// import moments from "./users/moments/MomentsReducers";
import posts from "./users/posts/PostsReducers";
import stories from "./users/stories/StoriesReducers";

import auth from "./auth/auth";
import message from "./auth/message";
import home from "./users/home/HomeReducers";

export default combineReducers({
    auth,
    message,
    home,
    // profile,
    // birthday,
    // messenger,
    // moments,
    posts,
    stories,
});