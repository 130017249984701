import React, { useRef, useEffect, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  VKShareButton,
  LineShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  VKIcon,
  LineIcon,
  TumblrIcon,
  EmailIcon,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import PostCardDownload from './PostCardDownload';

const ShareComponent = ({ post, isShareDialogOpen, toggleShareDialog, shareUrl, shareTitle, copyToClipboard, isDownload }) => {
  const dialogRef = useRef(null);
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        toggleShareDialog();
      }
    };

    if (isShareDialogOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShareDialogOpen, toggleShareDialog]);

  const handleDownload = () => {
    if (contentRef.current) {
      if (loading) return;
      setLoading(true);
      const element = contentRef.current;
      element.style.display = 'block';
      const images = contentRef.current.querySelectorAll('img');

      const imagePromises = Array.from(images).map((img) => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = reject;
          }
        });
      });

      Promise.all(imagePromises)
        .then(() => {
          if (contentRef.current) {
            html2canvas(contentRef.current, { useCORS: true, allowTaint: true, scale: 6, backgroundColor: 'transparent' })
              .then((canvas) => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = image;
                link.download = `${post.id}.jpg`;
                link.click();
                element.style.display = 'none';
                toggleShareDialog();
                setLoading(false);
              })
              .catch((error) => {
                console.error('Gagal menghasilkan tangkapan layar:', error);
                element.style.display = 'none';
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          console.error('Gagal memuat gambar:', error);
          element.style.display = 'none';
          setLoading(false);
        });
    }
  };

  return (
    isShareDialogOpen && (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-stretch md:justify-center items-end p-4">
        <div
          ref={dialogRef}
          className="w-full max-w-lg bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 shadow-lg rounded-lg p-4 animate-slide-up"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Share This Post</h3>
            <button
              onClick={toggleShareDialog}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
            </button>
          </div>

          {isDownload && (
            <div ref={contentRef} style={{
              maxWidth: '350px',
              width: 'auto', padding: '0px', margin: '0px', borderRadius: '15px', backgroundColor: 'transparent', color: 'transparent', display: 'none'
            }}>
              <PostCardDownload post={post} />
            </div>
          )}

          <p className="text-gray-500 dark:text-gray-400 mb-4">Share via social media or copy the link.</p>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-3 mb-4">
            <FacebookShareButton
              url={shareUrl}
              quote={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <FacebookIcon size={32} round />
              <span className="dark:text-white">Facebook</span>
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
            >
              <TwitterIcon size={32} round />
              <span className="dark:text-white">X (Twitter)</span>
            </TwitterShareButton>
            <LinkedinShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
            >
              <LinkedinIcon size={32} round />
              <span className="dark:text-white">LinkedIn</span>
            </LinkedinShareButton>
            <WhatsappShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              <WhatsappIcon size={32} round />
              <span className="dark:text-white">WhatsApp</span>
            </WhatsappShareButton>
            <TelegramShareButton
              url={shareUrl}
              title={shareTitle}
              className="flex items-center gap-2 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              <TelegramIcon size={32} round />
              <span className="dark:text-white">Telegram</span>
            </TelegramShareButton>
            <FacebookMessengerShareButton
              url={shareUrl}
              appId="your-facebook-app-id"
              className="flex items-center gap-2 p-2 bg-blue-800 text-white rounded-md hover:bg-blue-900"
            >
              <FacebookMessengerIcon size={32} round />
              <span className="dark:text-white">Messenger</span>
            </FacebookMessengerShareButton>
            <PinterestShareButton
              url={shareUrl}
              media={shareUrl} // Assuming the URL points to an image
              className="flex items-center gap-2 p-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              <PinterestIcon size={32} round />
              <span className="dark:text-white">Pinterest</span>
            </PinterestShareButton>
            <VKShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-vk-600 text-white rounded-md hover:bg-vk-700"
            >
              <VKIcon size={32} round />
              <span className="dark:text-white">VK</span>
            </VKShareButton>
            <LineShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-line-600 text-white rounded-md hover:bg-line-700"
            >
              <LineIcon size={32} round />
              <span className="dark:text-white">Line</span>
            </LineShareButton>
            <TumblrShareButton
              url={shareUrl}
              className="flex items-center gap-2 p-2 bg-tumblr-600 text-white rounded-md hover:bg-tumblr-700"
            >
              <TumblrIcon size={32} round />
              <span className="dark:text-white">Tumblr</span>
            </TumblrShareButton>
            <EmailShareButton
              url={shareUrl}
              subject={shareTitle}
              body={shareUrl}
              className="flex items-center gap-2 p-2 bg-email-600 text-white rounded-md hover:bg-email-700"
            >
              <EmailIcon size={32} round />
              <span className="dark:text-white">Email</span>
            </EmailShareButton>

            {isDownload && (
              <>

                <button
                  onClick={handleDownload}
                  className={`flex items-center gap-2 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-500 col-span-2 sm:col-span-1 lg:col-span-1 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                  disabled={loading}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} className="w-4 h-4 animate-spin" />
                  ) : (
                    <FontAwesomeIcon icon={faDownload} className="w-4 h-4" />
                  )}
                  <span>{loading ? 'Downloading...' : 'Download'}</span>
                </button>
              </>
            )}
            <button
              onClick={copyToClipboard}
              className="flex items-center gap-2 p-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 col-span-2 sm:col-span-1 lg:col-span-1"
            >
              <FontAwesomeIcon icon={faLink} className="w-4 h-4" />
              <span>Copy Link</span>
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ShareComponent;
