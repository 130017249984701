import {
    REQUEST_REDIRECT,
    REDIRECT_SUCCESS,
    REDIRECT_FAIL,
    REQUEST_CALLBACK,
    CALLBACK_SUCCESS,
    CALLBACK_FAIL,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    RESET_SUCCESS,
    RESET_FAIL,
    LOGOUT,
    SET_MESSAGE,
} from './types';

import AuthService from "../../services/auth.service";


export const redirect = () => async (dispatch) => {
    dispatch({
        type: REQUEST_REDIRECT
    });
    try {
        const res = AuthService.redirect();
        dispatch({
            type: REDIRECT_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: REDIRECT_FAIL
        })
    }
}

export const callback = (code) => async (dispatch) => {
    dispatch({
        type: REQUEST_CALLBACK
    });
    try {
        const res = AuthService.callback(code);
        dispatch({
            type: CALLBACK_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CALLBACK_FAIL
        })
    }
}

export const register = (name, email, password, c_password) => (dispatch) => {
    return AuthService.register(name, email, password, c_password).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            })
            return Promise.resolve();
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message || error.message || error.toString());
            dispatch({
                type: REGISTER_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};

export const login = (email, password) => (dispatch) => {
    return AuthService.login(email, password).then(
        (data) => {
            console.log("Login response:", data);  // Debugging
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });
            return Promise.resolve(data);
        },
        (error) => {
            console.error("Login error:", error);  // Debugging
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject(error);
        }
    );
};


export const reset = (email) => (dispatch) => {
    return AuthService.reset(email).then(
        (response) => {
            dispatch({
                type: RESET_SUCCESS,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: response.data.message,
            })
            return Promise.resolve();
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message || error.message || error.toString());
            dispatch({
                type: RESET_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    )
}

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    });
};