import axios from "axios";
import { API_URL } from "./base_url";
import authHeader from "./auth-header";

class AuthService {
    redirect = async () => {
        return axios.get(API_URL + '/auth/redirect', { headers: authHeader() });
    }

    callback = async (code) => {
        const encodedCode = encodeURIComponent(code);
        return axios.get(API_URL + '/auth/callback', { params: { encodedCode }, headers: authHeader() });
    }

    register = async (name, email, password, c_password) => {
        return axios.post(API_URL + "/auth/register", {
            name,
            email,
            password,
            c_password
        }, {
            headers: {
                TokenApp: 'cHbOpF3QWtISkuaUCEOuKXHY8iPhD5afUx5CQ2nUt4U7'
            }
        });
    }

    login = async (email, password) => {
        return axios.post(API_URL + "/auth/login", {
            email,
            password
        }, {
            headers: authHeader(),
        }).then(response => {
            console.log("Response status:", response.status);
            if (response.status === 200) {
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem("user", JSON.stringify(response.data.user));
                window.dispatchEvent(new Event("storage"));
                return response.data;
            } else {
                throw new Error("Unexpected response status: " + response.status);
            }
        });
    }

    reset = async (email) => {
        return axios.post(API_URL + "/auth/reset", {
            email
        }, {
            headers: {
                TokenApp: 'cHbOpF3QWtISkuaUCEOuKXHY8iPhD5afUx5CQ2nUt4U7'
            }
        }).then((response) => {
            return response.data
        })
    }

    logout = async () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
    }
}


export default new AuthService();

