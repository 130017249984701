import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

const ProtectedRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const checkAuth = () => {
            try {
                const accessToken = localStorage.getItem("accessToken");
                const user = JSON.parse(localStorage.getItem("user"));

                setIsAuthenticated(!!accessToken && !!user?.id);
            } catch (error) {
                console.error("Error parsing user data:", error);
                setIsAuthenticated(false);
            }
        };

        checkAuth();
        window.addEventListener("storage", checkAuth);

        return () => {
            window.removeEventListener("storage", checkAuth);
        };
    }, []);

    const shouldRender = useMemo(() => isAuthenticated, [isAuthenticated]);

    return shouldRender ? <Outlet /> : <Navigate to="/auth/login" replace />;
};

export default ProtectedRoute;
