import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from "../../actions/auth/auth";
import { useNavigate } from "react-router-dom";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';


export default function LoginPage() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isEnable, setEnable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    const handleKeyUp = () => {
        if (email.length > 0 && password.length >= 6 && isChecked) setEnable(false);
        else setEnable(true);
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        handleKeyUp();
    };

    useEffect(() => {
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        dispatch(login(email, password))
            .then((res) => {
                console.log("Login successful:", res);
                setIsLoading(false);
                navigate("/home");
            })
            .catch((error) => {
                console.error("Login failed:", error);
                setIsLoading(false);
            });
    };

    const handleGoogleLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/redirect`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('No URL found in the response');
            }
        } catch (error) {
            console.error('Error fetching Google login URL:', error);
        }
    };
    return (
        <div className="flex items-center justify-center h-screen bg-white dark:bg-dark-main">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-second shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex items-center justify-center">
                        <img src='/img/icon.png' alt="Trafalines logo" className="w-20 sm:w-10 lg:w-20 h-auto" />
                    </div>
                    {message && (
                        <div className="text-center m-2">
                            <div className="bg-red-500 text-white text-sm font-medium py-2 px-4 rounded-lg dark:bg-red-600">
                                {message}
                            </div>
                        </div>
                    )}

                    <br />
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white" htmlFor="username">{t('label_email')}</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="email-input"
                            type="email"
                            placeholder={t('label_email')}
                            value={email}
                            onKeyUp={handleKeyUp}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2 dark:text-white" htmlFor="password">{t('label_password')}</label>
                        <div className='relative'>
                            <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                id="password-input"
                                type={showPassword ? "text" : "password"}
                                placeholder="******************"
                                onKeyUp={handleKeyUp}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-3 flex items-center text-gray-600"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        </div>
                    </div>
                    <Link to="/auth/forget-password"
                        class="text-sm font-medium text-blue-600 hover:underline"> {t('btn_to_reset')}</Link>
                    <div className="mt-0">
                        <input
                            type="checkbox"
                            className="rounded-lg mr-2"
                            id="terms"
                            name="terms"
                            required
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="terms" className="text-sm font-medium text-black dark:text-white">
                            I agree to the {' '}
                            <span className="text-blue-500 hover:underline">
                                <a href={process.env.REACT_APP_TERM_CONDITIONS_URL}>terms & conditions</a>
                            </span>{' '}
                            and{' '}
                            <span className="text-blue-500 hover:underline">
                                <a href={process.env.REACT_APP_PRIVACY_POLICY_URL}>privacy policy</a>
                            </span>
                        </label>
                    </div>

                    {isLoading ? (
                        <button
                            className="w-full mt-6 bg-transparent text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            id="button-input"
                            disabled
                            type='button'
                        >
                            <div role="status" className="text-center flex justify-center items-center">
                                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin text-center dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    ) : (
                        <button
                            className={`w-full mt-2 ${isEnable ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-500 hover:bg-green-700'
                                } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                            id="button-input"
                            type='submit'
                            disabled={isEnable}
                        >
                            {t('btn_login')}
                        </button>

                    )}

                    <div class="flex justify-between items-center space-x-3 mt-2">
                        <div class="bg-gray-200 h-[2px] w-full dark:bg-gray-700"></div>
                        <p class="text-gray-500 font-semibold text-base dark:text-white">
                            OR</p>
                        <div class="bg-gray-200 h-[2px] w-full dark:bg-gray-700"></div>
                    </div>

                    <div className='mt-2 mb-4 flex justify-center items-center'>
                        <div onClick={handleGoogleLogin}
                            className="w-full flex justify-center items-center cursor-pointer text-white bg-red-500 hover:bg-red-700 text-center  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                        > <FontAwesomeIcon icon={faGoogle} className='mr-4'></FontAwesomeIcon>
                            Sign in with Google
                        </div>
                    </div>

                    <div className="text-center mt-2">
                        <div className="inline-block align-baseline font-bold text-sm text-black dark:text-white">
                            Don't have an account? <span className='text-blue-500'><Link to='/auth/register'> Register</Link></span>
                        </div>
                    </div>
                    <p class="mt-2 text-center text-sm font-bold text-gray-500 dark:text-white">Visit the official site <span
                        class="text-blue-500"><a
                            href={process.env.REACT_APP_OFFICIAL_URL}>here</a></span></p>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy; trafalines.com Community {(new Date().getFullYear())}
                </p>
            </div>
        </div>
    );
}


