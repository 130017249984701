export default function authHeader() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
        return {
            'App_Key': process.env.REACT_APP_APP_KEY,
            'Authorization': 'Bearer' + accessToken
        };
    } else {
        return {
            'App_Key': process.env.REACT_APP_APP_KEY
        };
    }


}