import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/icon.png';
import { faSearch, faHome, faHeart, faImages, faStore, faUserCircle, faAppleAlt, faComments, faPlayCircle, faMobile, faUtensils, faTools, faBars, faPlay, faNewspaper, faCompass, faMobileAlt, faCog, faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from '../util/ThemeToggle.js';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../../actions/auth/auth";
import { useNavigate } from "react-router-dom";
import UserMenu from './UserMenu.js';
import { retrieveCurrentUser } from '../../../actions/home/home';
import { Tooltip } from 'react-tooltip';

export default function HeaderWidget() {
    let history = useNavigate();
    const { isLoading, currentUser } = useSelector(state => state.home);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(retrieveCurrentUser());
    // }, [dispatch]);

    function logoutAction() {
        dispatch(logout());
        history("/auth/login");
    }

    return (
        <div className='hidden md:block'>
            {/* <!-- NAV --> */}
            <nav className="bg-white dark:bg-dark-second h-max md:h-14 w-full shadow flex flex-col md:flex-row items-center justify-center md:justify-between fixed top-0 z-50 border-b dark:border-dark-third">
                {/* <!-- LEFT NAV --> */}
                <div className="flex items-center justify-center md:w-max px-4 py-2 w-full">
                    <Link to="/" className="mr-2 hidden md:inline-block">
                        <img src={Logo} alt="Trafaline" className="w-24 sm:w-20 lg:w-10 h-auto" />
                    </Link>
                    <Link to="/" className="inline-block md:hidden">
                        <img src={Logo} alt="" className="w-8 h-auto" />
                    </Link>
                    <div className="flex items-center justify-between space-x-1">
                        <div className="relative bg-gray-100 dark:bg-dark-third px-2 py-2 w-auto h-10 sm:w-11 sm:h-11 lg:h-10 lg:w-full xl:w-full xl:pl-1 xl:pr-3 rounded-full flex items-center justify-center cursor-pointer">
                            <FontAwesomeIcon icon={faSearch} className="ml-4 text-gray-400" />
                            <i className='bx bx-search-alt-2 text-sm text-gray-400 not-italic pl-2 pr-4 xl:mr-2 dark:text-dark-txt'>Search People</i>
                        </div>
                    </div>
                </div>
                {/* <!-- END LEFT NAV -->

                    <!-- MAIN NAV --> */}
                <ul className="flex w-full lg:w-max space-x-2 ml-[-65px] items-center justify-center">
                    <li className="w-auto text-center">
                        <Link
                            to="/home"
                            data-tooltip-id="beranda"
                            data-tooltip-content="News"
                            data-tooltip-place="bottom"
                            className="w-auto text-2xl py-2 px-3 xl:px-6 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt"
                        >
                            <FontAwesomeIcon icon={faNewspaper} />
                        </Link>
                        <Tooltip id="beranda" />
                    </li>
                    <li className="w-auto text-center">
                        <Link to="/explorer"
                            data-tooltip-id="explorer"
                            data-tooltip-content="Explorer"
                            data-tooltip-place="bottom"
                            className="w-auto text-2xl py-2 px-3 xl:px-6 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                            <FontAwesomeIcon icon={faCompass} />
                        </Link>
                        <Tooltip id="explorer" />
                    </li>
                    <li className="w-auto text-center">
                        <Link to="/watch"
                            data-tooltip-id="watch"
                            data-tooltip-content="Watch"
                            data-tooltip-place="bottom"
                            className="w-auto text-2xl py-2 px-3 xl:px-6 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                            <FontAwesomeIcon icon={faPlayCircle} />
                        </Link>
                        <Tooltip id="watch" />
                    </li>
                    <li className="w-auto text-center">
                        <Link to="/notification"
                            data-tooltip-id="notification"
                            data-tooltip-content="Notification"
                            data-tooltip-place="bottom"
                            className="w-auto text-2xl py-2 px-3 xl:px-6 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                            <FontAwesomeIcon icon={faHeart} />
                            <span className="text-xs absolute top-0 right-1/4 bg-red-500 text-white font-semibold rounded-full px-1 text-center">9+</span>
                        </Link>
                        <Tooltip id="notification" />
                    </li>
                    <li className="w-auto text-center">
                        <Link to="/menu"
                            data-tooltip-id="more"
                            data-tooltip-content="More"
                            data-tooltip-place="bottom"
                            className="w-auto text-2xl py-2 px-3 xl:px-6 cursor-pointer text-center inline-block rounded text-gray-600 hover:bg-gray-100 dark:hover:bg-dark-third dark:text-dark-txt relative">
                            <FontAwesomeIcon icon={faTh} />
                        </Link>
                        <Tooltip id="more" />
                    </li>
                </ul>
                {/* <!-- END MAIN NAV --> */}

                {/* <!-- RIGHT NAV --> */}
                <ul className="hidden md:flex mx-4 items-center justify-center">
                    <li>
                        <Link to="/conversation"
                            data-tooltip-id="chat"
                            data-tooltip-content="Chat"
                            data-tooltip-place="bottom"
                            className="text-xl text-gray-600 grid place-items-center bg-white dark:bg-dark-third dark:text-dark-txt rounded-full mx-1 p-3 cursor-pointer hover:bg-gray-100 relative">
                            <FontAwesomeIcon icon={faComments} />
                            <span className="text-xs absolute top-0 right-0 bg-red-500 text-white font-semibold rounded-full px-1 text-center">9</span>
                        </Link>
                        <Tooltip id='chat' />
                    </li>

                    <li>
                        <Toggle />
                    </li>

                    {
                        currentUser ? (
                            <UserMenu
                                photos={currentUser.photos.files_small_thumbnail}
                                user={currentUser}
                                logoutAction={logoutAction}
                            />
                        ) : (
                            <div className='  className="flex mr-3 ml-3 text-sm bg-gray-800 w-6 h-6 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"'></div>
                        )
                    }
                </ul>
                {/* <!-- END RIGHT NAV --> */}
            </nav>
        </div>
    );

}