import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store/store.js";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n/i18n.js';
import { ThemeProvider } from './components/users/util/ThemeContext.js';
import NavigationPage from './screen/users/NavigationPage';
import 'react-tooltip/dist/react-tooltip.css';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
        <ThemeProvider>
          <Suspense fallback={<center>
          </center>}>
            <Provider store={store}>
              <Router>
                <NavigationPage />
              </Router>
            </Provider>
          </Suspense>
        </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
