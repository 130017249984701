import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/icon.png';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../actions/auth/auth';

export default function RegisterPage() {
    const { t, i18n } = useTranslation();

    let navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [birthday, setBirthday] = useState("");
    const [password, setPassword] = useState("");
    const [confir_password, setConfirPassword] = useState("");

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault();
        console.log(name, email, birthday, password, confir_password);
        dispatch(register(name, email, birthday, password, confir_password))
            .then((res) => {
                console.log(res);
                navigate("/auth/login");
                window.location.reload();
            })
            .catch(() => {

            })
    }

    return (
        <div className="flex items-center justify-center h-screen bg-white dark:bg-dark-main">
            <div className="w-full">
                <form onSubmit={handleSubmit} className="bg-white dark:bg-dark-second shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex items-center justify-center mb-3">
                        <img src={Logo} alt="Trafaline logo" className="w-20 sm:w-10 lg:w-20 h-auto" />
                    </div>
                    {message && (
                        <div className='form-group'>
                            <div className='alert alert-danger' role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <div className="mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="name">Name</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="name-input"
                            type="text"
                            value={name}
                            placeholder="Name"
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="email-input"
                            type="email"
                            value={email}
                            placeholder="Email"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="password">Password</label>
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="password-input"
                            type="password"
                            placeholder="******************"
                            value={password}
                            onChange={(event => setPassword(event.target.value))}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="block text-gray-700 text-sm font-bold mb-2" for="password">Confirmation Password</label>
                        <input
                            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id="confir-password-input"
                            type="password"
                            placeholder="******************"
                            value={confir_password}
                            onChange={(event => setConfirPassword(event.target.value))}
                        />
                    </div>
                    <div className="mt-0">
                        <input
                            type="checkbox"
                            className="rounded-lg mr-2"
                            id="terms"
                            name="terms"
                            required
                        />
                        <label htmlFor="terms" className="text-sm font-medium text-black">
                            I agree to the {' '}
                            <span className="text-blue-500 hover:underline">
                                <a href={process.env.REACT_APP_TERM_CONDITIONS_URL}>terms & conditions</a>
                            </span>{' '}
                            and{' '}
                            <span className="text-blue-500 hover:underline">
                                <a href={process.env.REACT_APP_PRIVACY_POLICY_URL}>privacy policy</a>
                            </span>
                        </label>
                    </div>
                    <button
                        className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        id='button-input'
                    >
                        Register
                    </button>

                    <div className="text-center mt-2">
                        <Link to="/auth/login" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Already have Account?, Login
                        </Link>
                    </div>
                    <div className="text-center">
                        <Link to="/auth/forget-password" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                            Forgot Password?
                        </Link>
                    </div>
                </form>
                <p className="text-center text-gray-500 text-xs">
                    &copy; trafalines.com Community {(new Date().getFullYear())}
                </p>
            </div>
        </div>
    );
}
