import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComments, faSearch, faTh, faBell } from "@fortawesome/free-solid-svg-icons";
import Stories from '../../../components/users/stories/StoriesWidget';
import FormAddPostsWidget from '../../../components/users/posts/FormAddPostsWidget';
import PostsWidget from '../../../components/users/posts/PostsWidget';
import { retrieveAllPosts } from '../../../actions/posts/PostsActions';
import PostsPlaceholder from '../../../components/placeholder/home/PostsPlaceholder';
import MemoriesPanel from '../../../components/users/posts/MemoriesPanel';
import ToggleMobile from '../../../components/users/util/ThemeToggleMobile';

export default function PostsPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoadingListPost, listPosts, hasMoreDataPost, loadingMoreDataPost } = useSelector(state => state.posts);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(retrieveAllPosts(page));
    }, [dispatch, page]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
                if (!loadingMoreDataPost) {
                    setPage(prevPage => prevPage + 1);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loadingMoreDataPost, hasMoreDataPost]);

    return (
        <div className="w-full lg:w-2/3 xl:w-2/5 pt-8 lg:pt-16 mt-2 lg:mt-0">
            <div className="xl:hidden lg:hidden">
                <div className='fixed top-0 bg-white dark:bg-dark-second px-3 h-[50px] md:h-14 w-full shadow flex flex-col md:flex-row md:justify-between z-50 dark:border-dark-third'>
                    <div className='flex items-center justify-between'>
                        <div className='flex'>
                            <p className="font-normal text-md text-gray-600 dark:text-dark-txt">Beranda</p>
                        </div>
                        <div className='flex'>
                            <div className="relative space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faComments} className="text-gray-600 dark:text-dark-txt text-xl" />
                                <span className="text-xs absolute top-1 -right-1 bg-red-500 text-white font-semibold rounded-full px-1 text-center">9</span>
                            </div>
                            <div className="relative space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faBell} className="text-gray-600 dark:text-dark-txt text-xl" />
                                <span className="text-xs absolute top-1 -right-1 bg-red-500 text-white font-semibold rounded-full px-1 text-center">9</span>
                            </div>
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <FontAwesomeIcon icon={faSearch} className="text-gray-600 dark:text-dark-txt text-xl" />
                            </div>
                            <div className="space-x-2 ml-3 mr-3 justify-center items-center text-xl py-2 rounded-lg cursor-pointer text-gray-500 dark:text-dark-txt">
                                <ToggleMobile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* stories */}
            <Stories />
            {/* POST FORM */}
            <div className='flex items-center justify-center'>
                <div className='w-full lg:w-96 mt-2'>
                    <MemoriesPanel />
                    <FormAddPostsWidget />
                    {/* <SuggestionPeople />
                    {/* LIST POST */}
                    <div className='flex-grow'>
                        {isLoadingListPost && listPosts.length === 0 ? (
                            <PostsPlaceholder />
                        ) : (
                            listPosts.length > 0 ? (
                                listPosts.map(post => (
                                    post?.id ? (
                                        <PostsWidget key={post.id} post={post} />
                                    ) : null
                                ))
                            ) : (
                                <p className="text-center text-gray-500">No posts available</p>
                            )
                        )}
                    </div>
                    {/* END LIST POST */}
                    {loadingMoreDataPost && (
                        <div className="flex items-center justify-center mt-4">
                            <div role="status" className="text-center">
                                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin text-center dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
