import React from 'react';
import HeaderBar from '../../components/users/header/HeaderWidget';
import Sidebar from '../../components/users/sidebar/SidebarWidget';
import RightSidebarWidget from '../../components/users/sidebar/RightSidebarWidget';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import BottomNavigationWidget from '../../components/users/header/BottomNavigationWidget';

import LoginPage from '../welcome/LoginPage';
import RegisterPage from '../welcome/RegisterPage';
import ForgetPage from '../welcome/ForgetPage';
import CallbackPage from '../welcome/CallbackPage';

import DetailsPosts from '../users/posts/DetailsPostsPage';
import Index from '../users/posts/PostsPage';
import Explorer from '../users/posts/ExplorerPage';
import Watch from '../users/posts/WatchPage';
import Tvs from '../users/posts/TvsPage';
import ForYouPage from './posts/ForYouPage';
import PostsLink from './posts/PostsLinkPage';
import PostsBg from './posts/PostsBackgroundPage';
import PopularPostsPage from './posts/PopularPostsPage';
import MemoriesPage from './posts/MemoriesPage';
import ProtectedRoute from './ProtectedRoute';
import AuthMiddleware from './AuthMiddleware';

export default function NavigationPage() {
    const location = useLocation();
    const hideSidebarRoutes = ['/auth/login', '/auth/register', '/auth/forget-password', '/auth/callback'];
    const shouldHideSidebar = hideSidebarRoutes.includes(location.pathname);

    return (
        <div>
            <div className="bg-white dark:bg-dark-main">
                {/* {!shouldHideSidebar && <Sidebar />} */}
                {!shouldHideSidebar && <HeaderBar />}
                {/* {!shouldHideSidebar && <RightSidebarWidget />} */}
                {!shouldHideSidebar && <BottomNavigationWidget />}
                <main className="flex justify-center min-h-screen pb-[60px]">
                    <Routes>
                        <Route path="/" element={<Navigate to="/foryou" />} />

                       
                        <Route path="/auth/callback" element={<CallbackPage />} />
                        <Route path="/auth/login" element={<LoginPage />} />
                        <Route path="/auth/register" element={<RegisterPage />} />
                        <Route path="/auth/forget-password" element={<ForgetPage />} />

                        <Route element={<ProtectedRoute />}>
                            <Route path="/home" element={<Index />} />
                            <Route path="/explorer" element={<Explorer />} />
                            <Route path="/memories" element={<MemoriesPage />} />
                            <Route path="/popular" element={<PopularPostsPage />} />
                            <Route path="/watch" element={<Watch />} />
                            <Route path="/tvs" element={<Tvs />} />
                            <Route path="/background" element={<PostsBg />} />
                            <Route path="/link" element={<PostsLink />} />
                        </Route>

                        <Route path="/foryou" element={<ForYouPage />} />
                        <Route path="/post/:id" element={<DetailsPosts />} />
                    </Routes>
                </main>
            </div>
        </div>
    );
}
